export interface IAdfoxConstants {
  p1: string;
  p2: string;
  pct?: string;
  pfc?: string;
  pfb?: string;
  plp?: string;
  pli?: string;
  pop?: string;
  pt?: string;
}

export const ADFOX_NEW_MEDIA_CONSTANTS = {
  p1: 'crutn',
  p2: 'fjjq',
};

export const ADFOX_MEDIA_BANNER_LABEL = 'Home_MediaBanner';

export const adfoxJKConstants: IAdfoxConstants = {
  p1: 'buldx',
  p2: 'fice',
  pct: 'a',
  pfc: 'a',
  pfb: 'a',
  plp: 'a',
  pli: 'a',
  pop: 'a',
  pt: 'b',
};

export const adfoxDemandBannerConstants = {
  p1: 'cwshz',
  p2: 'hzfe',
};

import React from 'react';
import { useSelector } from 'react-redux';

import { SeoCrossLinks } from '../../components/SeoCrossLinks';
import { selectCrossLinks, selectIsCrossLinksEnabled } from '../../selectors/seoCrossLinks';

export function SeoCrossLinksContainer() {
  const crossLinks = useSelector(selectCrossLinks);
  const isCrossLinksEnabled = useSelector(selectIsCrossLinksEnabled);

  if (!isCrossLinksEnabled || !crossLinks || !crossLinks.length) {
    return null;
  }

  return <SeoCrossLinks seoBlocks={crossLinks} />;
}

import * as React from 'react';
import {
  connect,
  TAdFoxBannerProps as TAdFoxBannerComponentProps,
  IAdFoxBannerOnLoadData,
} from '@cian/adfox-component';
import { OwnTemplates } from './OwnTemplates';
import { InitialAdfoxAnalyticsPayload, withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';

const AdFoxBannerComponent = connect(OwnTemplates);

type TAdFoxBannerProps = TAdFoxBannerComponentProps & {
  disableYan?: boolean;
  postViewAnalyticsData: Pick<
    InitialAdfoxAnalyticsPayload,
    'place' | 'place__DEPRECATED' | 'name__DEPRECATED' | 'extra__DEPRECATED' | 'isSp'
  >;
  position?: number;
  galleryPosition?: number;
};

export const NewAdfoxBanner: React.FC<TAdFoxBannerProps> = props => {
  const [isHidden, setIsHidden] = React.useState(false);
  const { disableYan, postViewAnalyticsData, position, galleryPosition, onStub, onLoad, onError, ...sharedProps } =
    props;

  const handleLoad = React.useCallback(
    (data: IAdFoxBannerOnLoadData, containerId: string) => {
      const { bundleName } = data;

      if (disableYan && bundleName === 'banner.direct') {
        setIsHidden(true);
      }

      if (onLoad) {
        onLoad(data, containerId);
      }
    },
    [disableYan, onLoad],
  );

  return React.useMemo(() => {
    if (disableYan || isHidden) {
      return null;
    }

    const AdfoxWithPostView = withAdfoxPostViewAnalytics(AdFoxBannerComponent, {
      ...postViewAnalyticsData,
      position,
      galleryPosition,
    });

    return <AdfoxWithPostView {...sharedProps} onLoad={handleLoad} onStub={onStub} onError={onError} />;
  }, [isHidden]);
};

import { IAdFoxBannerOnLoadData, IAdFoxBannerOnErrorError } from '@cian/adfox-component';
import * as React from 'react';
import { useApplicationContext } from '../../utils/applicationContext';
import { NewAdfoxBanner } from '../NewAdfoxBanner';
import { IAdfoxConstants } from './constants';
import { ErrorBoundary } from '../ErrorBoundary';
// eslint-disable-next-line import/no-restricted-paths
import { useAdsDisabled } from '../../../browser/utils/hooks/useAdsDisabled';
import { useAdfoxParams } from '../../utils/adfox';
import { InitialAdfoxAnalyticsPayload } from '@cian/postview-analytics-utils';

export interface IAdfoxNewProps {
  constants: IAdfoxConstants;
  postViewAnalyticsData: Pick<
    InitialAdfoxAnalyticsPayload,
    'place' | 'place__DEPRECATED' | 'name__DEPRECATED' | 'extra__DEPRECATED' | 'isSp'
  >;
  onLoad?(data: IAdFoxBannerOnLoadData): void;
  onStub?(): void;
  onError?(error: IAdFoxBannerOnErrorError): void;
}

const AdfoxNewComponent = (props: IAdfoxNewProps) => {
  const { logger } = useApplicationContext();
  const { onStub, constants } = props;

  const adfoxParams = useAdfoxParams(constants);

  const onError = React.useCallback(
    (error: Error) => {
      logger.error(error, {
        message: 'Failed to render adfox banner',
        domain: 'AdfoxNew',
      });

      if (onStub) {
        onStub();
      }
    },
    [onStub, logger],
  );

  if (!adfoxParams) {
    return null;
  }
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { pr, pr1, ...rest } = adfoxParams;

  return (
    <ErrorBoundary onError={onError}>
      <NewAdfoxBanner
        params={rest}
        onLoad={props.onLoad}
        onStub={props.onStub}
        onError={props.onError}
        postViewAnalyticsData={props.postViewAnalyticsData}
        width="100%"
        minHeight="80px"
      />
    </ErrorBoundary>
  );
};

export const AdfoxNew = (props: IAdfoxNewProps) => {
  const isAdsDisabled = useAdsDisabled(() => {
    if (props.onStub) {
      props.onStub();
    }
  }, [props.onStub]);

  if (isAdsDisabled) {
    return null;
  }

  return <AdfoxNewComponent {...props} />;
};

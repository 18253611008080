import {
  Action,
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore as _createStore,
  Reducer,
} from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { defaultState as pageDefaultState } from '../../constants/page';
import {
  clearGeoReducer,
  clearReducer,
  geocodeReducer,
  metaReducer,
  selectCoworkingOfferTypeReducer,
  selectGeoReducer,
  selectOfferTypeReducer,
  selectRoomTypeReducer,
  setAreaReducer,
  setBSCentersReducer,
  setBuilderReducer,
  setDealTypeReducer,
  setJsonQueryReducer,
  setLandAreaReducer,
  setPosessorReducer,
  setPriceReducer,
  setPriceTypeReducer,
  setRegionReducer,
  setVillageReducer,
  setWorkplaceCountReducer,
  suggestionsReducer,
  geoSearchReducer,
  clientErrorsReducer,
  setCoworkingIdReducer,
  setFilterSpecialtyTypesReducer,
} from '../../reducers';
import { businessAppointmentsReducer } from '../../reducers/businessAppointments';
import { mortgageFilters } from '../../reducers/mortgage';
import { specialTabsReducer } from '../../reducers/specialTabs';
import { searchRealtorsReducer } from '../../reducers/searchRealtors';
import { setSpecialtyTypesReducer } from '../../reducers/specialtyTypes/setSpecialtyTypesReducer';
import { oldReducers } from '../../reducers/reducers';
import { IFeaturesState } from '../../types/features';
import { IFiltersState } from '../../types/filters';
import { IPageState } from '../../types/page';
import { IApplicationState, IReduxStore, TReduxActions, TReduxInitialState } from '../../types/redux';

import { createSimpleReducer } from './createSimpleReducer';
import { abUseExperimentsReducer } from '../../reducers/abUseExperiments';
import { geoSwitcherReducer } from '../../reducers/geoSwitcher';
import { mainpageReducer } from '../../reducers/mainpage';
import { IApplicationContext } from '../../types/application';
import { regionExpansionReducer } from '../../reducers/regionExpansion';
import { setBedsReducer } from '../../reducers/filters/jsonQuery/setBeds';
import { setDatesReducer } from '../../reducers/filters/jsonQuery/setDates';
import { lastRoomsReducer } from '../../reducers/filters/lastRooms';
import { userReducer } from '../../reducers/user';
import { IJsonQuery } from '../../../packages/api-models/common/json_query';
import { IUsefulLinkSchema } from '../../repositories/mainpage/entities/response/UsefulLinkSchema';
import { INewbuildingSeoBlock } from '../../types/newbuildingSeoBlock';
import { TAdfoxRequestedParams } from '../../types/adfox';
import { IRkData } from '../../types/flightRk';
import { defaultBackgroundReducer } from '../../reducers/defaultBackground';
import { datesModalReducer } from '../../reducers/filters/datesModal';
import { ICrossLinks } from '../../types/crossLinks';

interface IThunkExt {
  dispatch: ThunkDispatch<IApplicationState, IApplicationContext, TReduxActions>;
}

const featuresDefaultState: IFeaturesState = {
  isDobroshriftEnabled: false,
  isChildrenDayEnabled: false,
  crossLinksEnabled: false,
};

let storeReminder: IReduxStore;

export function createReduxStore(
  initialState: TReduxInitialState,
  context: IApplicationContext,
  debugEnabled: boolean,
) {
  const reducers = combineReducers<IApplicationState, TReduxActions>({
    ...oldReducers,
    filters: combineReducers<IFiltersState, TReduxActions>({
      jsonQuery: reduceReducers<IJsonQuery, TReduxActions>(
        setJsonQueryReducer,
        setDealTypeReducer,
        selectOfferTypeReducer,
        selectCoworkingOfferTypeReducer,
        setWorkplaceCountReducer,
        setFilterSpecialtyTypesReducer,
        selectRoomTypeReducer,
        setPriceReducer,
        setAreaReducer,
        setLandAreaReducer,
        setPosessorReducer,
        setRegionReducer,
        setVillageReducer,
        setBSCentersReducer,
        selectGeoReducer,
        clearGeoReducer,
        setBuilderReducer,
        setPriceTypeReducer,
        setCoworkingIdReducer,
        setBedsReducer,
        setDatesReducer,
        /** Всегда последний */
        clearReducer,
      ),
      meta: metaReducer,
      suggestions: suggestionsReducer,
      geoSearch: geoSearchReducer,
      clientErrors: clientErrorsReducer,
      geocode: geocodeReducer,
      lastRooms: lastRoomsReducer,
      datesModal: datesModalReducer,
    }),
    page: createSimpleReducer<IPageState>(pageDefaultState),
    features: createSimpleReducer(featuresDefaultState),
    mainpage: mainpageReducer,
    specialtyTypes: setSpecialtyTypesReducer,
    businessAppointments: businessAppointmentsReducer,
    mortgage: mortgageFilters,
    specialTabs: specialTabsReducer,
    searchRealtors: searchRealtorsReducer,
    abUseExperiments: abUseExperimentsReducer,
    dealRegions: createSimpleReducer<number[]>([]),
    buildersProjectsLocations: createSimpleReducer<number[]>([]),
    geoSwitcher: geoSwitcherReducer,
    regionExpansion: regionExpansionReducer,
    user: userReducer,
    usefulLinks: createSimpleReducer<IUsefulLinkSchema[]>([]),
    newbuildingsSeoBlock: createSimpleReducer<INewbuildingSeoBlock>({ city: null, region: null }),
    adfoxParams: createSimpleReducer<TAdfoxRequestedParams | null>(null),
    flightRkData: createSimpleReducer<IRkData | null>(null),
    defaultBackground: defaultBackgroundReducer,
    сrossLinks: createSimpleReducer<ICrossLinks[] | null>(null),
  });

  const composeEnhancers = debugEnabled ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

  const storeEnhancers = composeEnhancers(applyMiddleware(thunk.withExtraArgument(context)));

  const createStore = (): IReduxStore =>
    _createStore<IApplicationState, TReduxActions, IThunkExt, {}>(
      reducers,
      initialState as IApplicationState,
      storeEnhancers,
    );

  if (process.env.NODE_ENV === 'development' && debugEnabled) {
    if (!storeReminder) {
      storeReminder = createStore();
    }

    storeReminder.replaceReducer(reducers);

    return storeReminder;
  }

  return createStore();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reduceReducers<S, A extends Action<any> = AnyAction>(...reducers: Reducer<S, A>[]): Reducer<S, A> {
  return (prevState: S | undefined, action: A): S =>
    reducers.reduce((newState, reducer) => reducer(newState, action), prevState) as S;
}

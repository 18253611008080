import { IConfig } from '../../../types/config';

const initialState: IConfig = {
  frontContentUrl: '',
  backContentUrl: '',
  subdomain: 'www',
  abGroup: 100,
  isDomrfEnabled: false,
  salesLeaderInfo: null,
};

export const configReducer = (state = initialState) => {
  return state;
};
